import Section from "./section";
import AiOutputSettings from "./aiOutputSettings";
import AiMappingInstance from "./aiMappingInstance";
import aiMappings from "@/assets/js/aiMappings.json";
// const aiMappings = require("@/assets/js/AiMappings.json");

export default class AiSection extends Section {
  constructor(section) {
    super(section);
    this.mappings = (section.mappings || aiMappings).map(m => new AiMappingInstance(m))
    this.outputSettings = new AiOutputSettings(section.outputSettings ? section.outputSettings : {});
  }

  clone() {
    return new AiSection(this.export());
  }

  export() {
    return {
      ...this.exportBase(),
      mappings: this.mappings.map(m => m.export()),
      outputSettings: this.outputSettings
    };
  }

  static addStandardMappings() {
    return JSON.parse(JSON.stringify(aiMappings));
  }

  cleanMappings() {
    this.mappings = this.mappings.map((e) => {
      return { value: e.value, expression: e.expression, active: e.active };
    });
  }

  addNewMapping(value){
    this.mappings.push(new AiMappingInstance({value: value}))
  }

  removeMapping(index){
    this.mappings.splice(index, 1)
  }

  rearrange(from, to){
    const draggedMapping = this.mappings[from]
    this.mappings.splice(from, 1);
    this.mappings.splice(to, 0, draggedMapping);
  }

}