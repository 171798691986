import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia';

// Fontawesome stuff
import { library } from '@fortawesome/fontawesome-svg-core';
import { dom } from '@fortawesome/fontawesome-svg-core';
import icons from './assets/js/fontawesome';

library.add(icons);
dom.watch();

// Tippy stuff
import VueTippy from 'vue-tippy';

// standard vue setup
import App from './App.vue';
import router from './router';

import './assets/main.css';
const app = createApp(App);

//add router object to pinia store
const pinia = createPinia();
pinia.use(({ store }) => {
  store.$router = markRaw(router);
});

app.use(pinia);
app.use(router);
app.use(
  VueTippy,
  // // optional
  {
    // directive: 'tippy', // => v-tippy
    // component: 'tippy', // => <tippy/>
    defaultProps: {
      allowHTML: true,
      boundary: 'viewport',
      trigger: 'manual',
      role: 'dropdown',
      interactive: true,
      placement: 'right',
      offset: [0, 0],
      appendTo: () => document.body,
      onHidden(instance) {
        instance.destroy();
      },
    }, // props to pass to the component
  }
);

import GlobalSnackbars from './components/GlobalSnackbars.vue';
app.component('GlobalSnackbars', GlobalSnackbars);

import * as Sentry from '@sentry/vue';
// Sentry stuff
// Sentry.init({
//   app,
//   dsn: 'https://b32952e9ab09a6e9ce9fbb17aad331af@o951137.ingest.sentry.io/4506110125146112',
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ['localhost', 'staging.api.icrobotics.com', 'api.icrobotics.com'],
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//     }),
//     new Sentry.Replay(),
//     new Sentry.Integrations.Breadcrumbs({
//       console: false,
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

// Axios stuff
import * as Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

import { useMainStore } from './stores/main';
import { useUserStore } from './stores/user';
import appHelper from './assets/js/appHelper';
const store = useMainStore();
const userStore = useUserStore();
app.use(VueAxios, axios);
axios.defaults.baseURL = appHelper.getApiUrl();

axios.interceptors.request.use(function (config) {
  if (!config.url.includes('login')) {
    // Set bearer token on all requests
    config.headers['Authorization'] = 'Bearer ' + store.token;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status == 401) {
      if (error.response.config.url.includes('login')) return;

      if (router.currentRoute.value.name == 'template') store.handleExpiredToken();
      // if Unauthorized === no token or no valid token in request headers. go to login screen
      else store.logout();
    } else return Promise.reject(error);
  }
);

// Routing stuff

// Check for token authorization on each router navigation
router.beforeEach((to, from, next) => {
  store.$patch((state) => (state.loadingRoute = true));
  if (store.token) {
    // CALLING GET USER

    userStore.getUser().then((res) => next());
  } else if (!['login'].includes(to.name)) next('/login');
  else next();
});

router.afterEach(() => {
  store.$patch((state) => (state.loadingRoute = false));
});

app.mount('#app');
