import { faCircleInfo } from '@fortawesome/pro-light-svg-icons/faCircleInfo';
import { faGripDotsVertical } from '@fortawesome/pro-light-svg-icons/faGripDotsVertical';
import { faLinkSimple } from '@fortawesome/pro-light-svg-icons/faLinkSimple';
import { faGripLines } from '@fortawesome/pro-light-svg-icons/faGripLines';
import { faClipboard } from '@fortawesome/pro-light-svg-icons/faClipboard';
import { faDownload } from '@fortawesome/pro-light-svg-icons/faDownload';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { faCode } from '@fortawesome/pro-light-svg-icons/faCode';
import { faCodeSimple } from '@fortawesome/pro-light-svg-icons/faCodeSimple';
import { faSortNumericDown } from '@fortawesome/pro-light-svg-icons/faSortNumericDown';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faChevronDoubleRight } from '@fortawesome/pro-light-svg-icons/faChevronDoubleRight';
import { faTrophy } from '@fortawesome/pro-light-svg-icons/faTrophy';
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog';
import { faSignOut } from '@fortawesome/pro-light-svg-icons/faSignOut';
import { faFileSpreadsheet } from '@fortawesome/pro-light-svg-icons/faFileSpreadsheet';
import { faUserSlash } from '@fortawesome/pro-light-svg-icons/faUserSlash';
import { faKey } from '@fortawesome/pro-light-svg-icons/faKey';
import { faEllipsisVAlt } from '@fortawesome/pro-light-svg-icons/faEllipsisVAlt';
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons/faEllipsisV';
import { faEllipsis } from '@fortawesome/pro-light-svg-icons/faEllipsis';
import { faFileExport } from '@fortawesome/pro-light-svg-icons/faFileExport';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faCreditCard } from '@fortawesome/pro-light-svg-icons/faCreditCard';
import { faFile } from '@fortawesome/pro-light-svg-icons/faFile';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faStar } from '@fortawesome/pro-light-svg-icons/faStar';
import { faPlayCircle } from '@fortawesome/pro-light-svg-icons/faPlayCircle';
import { faHeadset } from '@fortawesome/pro-light-svg-icons/faHeadset';
import { faDatabase } from '@fortawesome/pro-light-svg-icons/faDatabase';
import { faFileCsv } from '@fortawesome/pro-light-svg-icons/faFileCsv';
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons/faPaperPlane';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import { faExchangeAlt } from '@fortawesome/pro-light-svg-icons/faExchangeAlt';
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { faStrikethrough } from '@fortawesome/pro-light-svg-icons/faStrikethrough';
import { faUnderline } from '@fortawesome/pro-light-svg-icons/faUnderline';
import { faItalic } from '@fortawesome/pro-light-svg-icons/faItalic';
import { faBold } from '@fortawesome/pro-light-svg-icons/faBold';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faHistory } from '@fortawesome/pro-light-svg-icons/faHistory';
import { faAlignJustify } from '@fortawesome/pro-light-svg-icons/faAlignJustify';
import { faColumns } from '@fortawesome/pro-light-svg-icons/faColumns';
import { faTh } from '@fortawesome/pro-light-svg-icons/faTh';
import { faSyncAlt } from '@fortawesome/pro-light-svg-icons/faSyncAlt';
import { faAtlas } from '@fortawesome/pro-light-svg-icons/faAtlas';
import { faBook } from '@fortawesome/pro-light-svg-icons/faBook';
import { faCalculator } from '@fortawesome/pro-light-svg-icons/faCalculator';
import { faTrash } from '@fortawesome/pro-light-svg-icons/faTrash';
import { faFileUpload } from '@fortawesome/pro-light-svg-icons/faFileUpload';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faCircleNotch } from '@fortawesome/pro-light-svg-icons/faCircleNotch';
import { faSpinner } from '@fortawesome/pro-light-svg-icons/faSpinner';
import { faRandom } from '@fortawesome/pro-light-svg-icons/faRandom';
import { faCaretDown } from '@fortawesome/pro-light-svg-icons/faCaretDown';
import { faCaretUp } from '@fortawesome/pro-light-svg-icons/faCaretUp';
import { faFileAlt } from '@fortawesome/pro-light-svg-icons/faFileAlt';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle';
import { faSave } from '@fortawesome/pro-light-svg-icons/faSave';
import { faChartPie } from '@fortawesome/pro-light-svg-icons/faChartPie';
import { faChartBar } from '@fortawesome/pro-light-svg-icons/faChartBar';
import { faUndo } from '@fortawesome/pro-light-svg-icons/faUndo';
import { faLevelUpAlt } from '@fortawesome/pro-light-svg-icons/faLevelUpAlt';
import { faArrowUpFromSquare } from '@fortawesome/pro-light-svg-icons/faArrowUpFromSquare';
import { faCircleSmall } from '@fortawesome/pro-light-svg-icons/faCircleSmall';
import { faCircle } from '@fortawesome/pro-light-svg-icons/faCircle';
import { faClone } from '@fortawesome/pro-light-svg-icons/faClone';
import { faQuestion } from '@fortawesome/pro-light-svg-icons/faQuestion';
import { faCircleP } from '@fortawesome/pro-light-svg-icons/faCircleP';
import { faParagraph } from '@fortawesome/pro-light-svg-icons/faParagraph';
import { faFrame } from '@fortawesome/pro-light-svg-icons/faFrame';
import { faText } from '@fortawesome/pro-light-svg-icons/faText';
import { faArrowRotateLeft } from '@fortawesome/pro-light-svg-icons/faArrowRotateLeft';
import { faArrowsRotate } from '@fortawesome/pro-light-svg-icons/faArrowsRotate';
import { faPlay } from '@fortawesome/pro-light-svg-icons/faPlay';
import { faMap } from '@fortawesome/pro-light-svg-icons/faMap';
import { faTools } from '@fortawesome/pro-light-svg-icons/faTools';
import { faArrowTurnDownLeft } from '@fortawesome/pro-light-svg-icons/faArrowTurnDownLeft';
import { faHorizontalRule } from '@fortawesome/pro-light-svg-icons/faHorizontalRule';
import { faFolder } from '@fortawesome/pro-light-svg-icons/faFolder';
import { faFolders } from '@fortawesome/pro-light-svg-icons/faFolders';
import { faSnooze } from '@fortawesome/pro-light-svg-icons/faSnooze';
import { faP } from '@fortawesome/pro-light-svg-icons/faP';
import { faBolt } from '@fortawesome/pro-light-svg-icons/faBolt';
import { faCommentPen } from '@fortawesome/pro-light-svg-icons/faCommentPen';
import { faHouse } from '@fortawesome/pro-light-svg-icons/faHouse';
import { faLanguage } from '@fortawesome/pro-light-svg-icons/faLanguage';
import { faMicrophone } from '@fortawesome/pro-light-svg-icons/faMicrophone';
import { faMoonStars } from '@fortawesome/pro-light-svg-icons/faMoonStars';
import { faMinus } from '@fortawesome/pro-light-svg-icons/faMinus';
import { faWindowMaximize } from '@fortawesome/pro-light-svg-icons/faWindowMaximize';
import { faWindowMinimize } from '@fortawesome/pro-light-svg-icons/faWindowMinimize';
import { faCommentDots } from '@fortawesome/pro-light-svg-icons/faCommentDots';
import { faFunction } from '@fortawesome/pro-light-svg-icons/faFunction';
import { faChevronDoubleLeft } from '@fortawesome/pro-light-svg-icons/faChevronDoubleLeft';
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars';
import { faBlockQuote } from '@fortawesome/pro-light-svg-icons/faBlockQuote';
import { faListUl } from '@fortawesome/pro-light-svg-icons/faListUl';
import { faSubscript } from '@fortawesome/pro-light-svg-icons/faSubscript';
import { faSuperscript } from '@fortawesome/pro-light-svg-icons/faSuperscript';
import { faCircleArrowRight } from '@fortawesome/pro-light-svg-icons/faCircleArrowRight';

export default {
  faTrash,
  faFileUpload,
  faSearch,
  faPlus,
  faTimes,
  faCircleNotch,
  faSpinner,
  faRandom,
  faCaretDown,
  faCaretUp,
  faFileAlt,
  faTimesCircle,
  faSave,
  faChartPie,
  faChartBar,
  faUndo,
  faLevelUpAlt,
  faCalculator,
  faBook,
  faAtlas,
  faSyncAlt,
  faTh,
  faColumns,
  faAlignJustify,
  faHistory,
  faEye,
  faEyeSlash,
  faBold,
  faItalic,
  faUnderline,
  faLink,
  faGlobe,
  faExchangeAlt,
  faQuestionCircle,
  faPaperPlane,
  faFileCsv,
  faDatabase,
  faHeadset,
  faPlayCircle,
  faStar,
  faUser,
  faFile,
  faCreditCard,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faFileExport,
  faEllipsisVAlt,
  faEllipsisV,
  faKey,
  faUserSlash,
  faFileSpreadsheet,
  faSignOut,
  faCog,
  faTrophy,
  faChevronDoubleRight,
  faCheck,
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faSortNumericDown,
  faCode,
  faCodeSimple,
  faPen,
  faDownload,
  faClipboard,
  faGripLines,
  faLinkSimple,
  faGripDotsVertical,
  faCircleInfo,
  faArrowUpFromSquare,
  faCircleSmall,
  faCircle,
  faClone,
  faQuestion,
  faCircleP,
  faParagraph,
  faFrame,
  faText,
  faArrowRotateLeft,
  faPlay,
  faMap,
  faTools,
  faEllipsis,
  faArrowTurnDownLeft,
  faHorizontalRule,
  faFolder,
  faFolders,
  faSnooze,
  faP,
  faBolt,
  faStrikethrough,
  faCommentPen,
  faHouse,
  faLanguage,
  faMicrophone,
  faMoonStars,
  faMinus,
  faWindowMaximize,
  faWindowMinimize,
  faCommentDots,
  faFunction,
  faArrowsRotate,
  faChevronDoubleLeft,
  faBars,
  faBlockQuote,
  faListUl,
  faSubscript,
  faSuperscript,
  faCircleArrowRight

};
