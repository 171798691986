import orderBy from 'lodash/orderBy'
export default class VariableGroup {
    constructor (group) {
        this.groups = orderBy(group.groups.map(g => new VariableGroup(g)), ['name'], ['asc'])
        this.variables = orderBy(group.variables, 'name', 'asc')
        this.name = group.name
        this.root = group.root
    }

    contains (filter) {
        filter = filter.toLowerCase()
        return this.groups.some(g => g.contains(filter))
            || filter.split(' ').every(filterSegment => this.variables.some(v => v.name.toLowerCase().indexOf(filterSegment) > -1 || (v.displayName || '').toLowerCase().indexOf(filterSegment) > -1))
    }

    hasVariable (variableName) { 
        return this.groups.some(g => g.hasVariable(variableName)) || this.variables.some(v => v.name.includes(variableName))
    }
}