export default class AiMappingInstance {
    constructor(mapping) {
      this.value = mapping.value || null;
      (this.expression = mapping.expression || null), 
      (this.active = mapping.active || false);
    }
  
    export() {
      return {
        value: this.value,
        expression: this.expression,
        active: this.active
      };
    }
  }