import { defineStore } from 'pinia';
import axios from 'axios';
import { useTemplateStore } from '@/stores/template';
import VariableHelpers from '@/assets/js/helpers/variableHelper.js';
import PreviewHelpers from '@/assets/js/helpers/previewHelper.js';
import SynonymHelpers from '@/assets/js/helpers/synonymHelper.js';
import Template from '@/assets/js/template/template.js';

export const useEditorStore = defineStore({
  id: 'editor',
  state: () => ({
    sidepanelWidth: parseInt(localStorage.getItem('userSidepanelWidth') || 400),
    templatePreview: null, // This is the main preview
    templatePreviewAsHTML: false,
    selectedDataset: null,
    selectedDatasetId: '',
    currentEditorView: 'template',
    activeTools: [],
    showSearch: false,
    searchResults: [],
    commandsKeydown: null,
    inlinePopupClientRect: null,
    removeListenerFunction: null,
    removeCommandsListListenerFunction: null,
    variableFormats: [],
    activeVariantIndex: 0,
    travelToPath: null,
    editorReadOnly: false,
    focusedPaths: [],
    structureIsFocused: false,
    showPreviewStats: false,
    showAISettings: false,
    sectionsLoading: [],
    sectionPreviewError: [],

    // AI synonym amounts
    synonymSuggestionsAmount: 5,
    variantRephraseAmounts: 2,

    sidePanelLockedToUI: true,
    inlineEditorMenuOpen: false,
    isRephrasing: false,

    dataFavorites: [],
  }),
  getters: {
    simpleVariableFormats: (state) => {
      const filteredFormats = {};
      for (const [key, value] of Object.entries(state.variableFormats)) {
        filteredFormats[key] = value.filter((v) => v.inputs === null);
      }
      return filteredFormats;
    },
  },
  actions: {
    // Preview Actions here ------

    getTemplatePreview({
      template,
      variables,
      includeVariableNames = !this.templatePreviewAsHTML,
      random = false,
      generateAIContent = false,
    }) {
      let seed = random ? Math.floor(Math.random() * 10000) + 1 : null;
      return axios
        .post(
          'editor/previewtemplate?includeVariableNames=' +
            includeVariableNames +
            (seed ? '&seed=' + seed : '') +
            ('&generateAIContent=' + generateAIContent),
          {
            template: template.export(),
            variables: variables,
          }
        )
        .then((result) => {
          // Testing to see if this is good
          this.setTemplatePreview(result.data);
        });
    },

    getTemplatePreviewForSectionType(type) {
      const templateStore = useTemplateStore();
      const templateContent = new Template({
        ...templateStore.template,
        sections: templateStore.template.sections.filter((s) => s.type == type),
      });
      templateContent.sections.forEach((s) => this.setSectionLoadingState(s.id));

      this.getTemplatePreview({
        template: templateContent,
        variables: templateStore.templateVariables,
        generateAIContent: true,
      })
        .then((preview) => {
          this.setTemplatePreview(preview);
        })
        .catch((e) => {
          templateContent.sections.forEach((s) => this.sectionLoadedError(s.id));
        })
        .finally(() => {
          templateContent.sections.forEach((s) => this.sectionLoaded(s.id));
        });
    },

    getTemplatePreviewForSection(sectionId) {
      const templateContent = new Template({
        ...templateStore.template,
        sections: templateStore.template.sections.filter((s) => s.id == sectionId),
      });
      this.setSectionLoadingState(sectionId);
      this.getTemplatePreview({
        template: templateContent,
        variables: templateStore.templateVariables,
        generateAIContent: true,
      })
        .then((preview) => {
          this.setTemplatePreview(preview);
        })
        .catch((e) => {
          templateContent.sections.forEach((s) => this.sectionLoadedError(s.id));
        })
        .finally(() => {
          templateContent.sections.forEach((s) => this.sectionLoaded(s.id));
        });
    },

    setTemplatePreview(preview) {
      if (!this.templatePreview) return (this.templatePreview = preview);
      const templateStore = useTemplateStore();
      let newPreviewSections = [...this.templatePreview.sections];
      const templateSectionIDs = templateStore.template.sections.map((s) => s.id);
      preview.sections.forEach((s) => {
        if (templateSectionIDs.includes(s.id)) {
          const sectionIndex = newPreviewSections.findIndex((ps) => ps.id == s.id);
          sectionIndex === -1 ? newPreviewSections.push(s) : (newPreviewSections[sectionIndex] = s);
        }
      });
      preview.sections = newPreviewSections;
      this.templatePreview = preview;
    },

    setSectionLoadingState(sectionId) {
      this.sectionPreviewError = [...this.sectionPreviewError.filter((e) => e != sectionId)];
      this.sectionsLoading = [...this.sectionsLoading, sectionId];
    },

    sectionLoaded(sectionId) {
      this.sectionsLoading = [...this.sectionsLoading.filter((e) => e != sectionId)];
    },

    sectionLoadedError(sectionId) {
      this.sectionPreviewError = [...this.sectionPreviewError, sectionId];
    },

    getVariantPreview(variantText) {
      const templateStore = useTemplateStore();
      let template = {
        metadata: templateStore.template.metadata,
        extraVariables: templateStore.template.extraVariables,
        globalSynonyms: templateStore.template.globalSynonyms,
        methods: templateStore.template.methods,
        sections: [{ sentences: [{ texts: [variantText] }], name: 'Body', id: 'body' }],
        language: templateStore.template.language,
      };
      return axios
        .post('editor/previewtemplate?includeVariableNames=false', {
          template: template,
          variables: templateStore.templateVariables,
        })
        .then((result) => {
          return result.data.sections[0].result ? result.data.sections[0].result.sentences : null;
        });
    },

    // ----------------------------

    getVariableFormats(params) {
      // const getData = () => {
      return axios.get(`editor/metadata/formats?templateLanguage=${params.templateLanguage}`).then((result) => {
        this.variableFormats = result.data;
      });
      // }
      // if(!params.templateLanguage){
      //   dispatch("getTemplate", params.templateId).then(res => {
      //         params.templateLanguage = res.language
      //         getData()
      //   })
      // } else {
      //   getData()
      // }
    },

    dataChanged() {
      const templateStore = useTemplateStore();
      // New data has been loaded, so we need to update the preview
      this.getTemplatePreview({
        template: templateStore.template,
        variables: templateStore.templateVariables,
      }).then(() => {
        // We need to update the focused paths, since the preview has changed but only if shown
        if (this.structureIsFocused) {
          this.setFocusedPaths(templateStore.templateSectionBeingEdited?.id);
        }
      });
    },

    setCurrentView(view) {
      this.currentEditorView = view;
    },

    setCommandsList(commandsFunction) {
      this.commandsKeydown = commandsFunction;
    },

    saveClientRect(clientRect) {
      this.inlinePopupClientRect = clientRect;
    },

    removeListeners() {
      this.removeListenerFunction?.removeKey();
      this.removeListenerFunction = null;
    },

    removeCommandsListListeners() {
      this.removeCommandsListListenerFunction?.removeKey();
      this.removeCommandsListListenerFunction = null;
    },

    setRemoveListenerFunction(removeListenerFunction) {
      this.removeListenerFunction = removeListenerFunction;
    },
    setRemoveCommandsListListenerFunction(removeListenerFunction) {
      this.removeCommandsListListenerFunction = removeListenerFunction;
    },

    setActiveVariantIndex(index) {
      this.activeVariantIndex = index;
    },

    editSentence({ sentence, element, sectionId, variables }) {
      const templateStore = useTemplateStore();
      console.log('editSentence');

      this.currentEditorView = 'template';
      if (templateStore.template.sections.find((s) => s.id === sectionId).type === 'ai')
        return templateStore.setAiSectionBeingEdited(sectionId);

      // this runs slow and causes a violation warning
      templateStore.setTemplateSectionBeingEdited(sectionId);
      templateStore.templateSectionBeingEdited.setPaths([]);
      templateStore.setActivePath(sentence.path);
      templateStore.setActiveSentenceFromPath(sentence.path);
      this.activeVariantIndex = sentence.path.pop();
    },

    testExpression(expression) {
      const templateStore = useTemplateStore();
      return axios
        .post('editor/expressions/check', {
          expression: expression,
          dependentVariables: VariableHelpers.getDependentVariables(
            expression,
            templateStore.combinedVariables
          ).reverse(), // Reverse - then checking the expressions also works if the users uses extravariables in extravariables
        })
        .then((result) => result.data);
    },

    setReadOnlyMode(value) {
      this.editorReadOnly = value;
    },

    setFocusedPaths(sectionId) {
      this.focusedPaths = PreviewHelpers.getPathsInPreveiwSection(
        this.templatePreview.sections.find((s) => s.id === sectionId)
      );
    },

    clearFocusedPaths() {
      this.focusedPaths = [];
    },

    getFormatExamples({ variable, formats }) {
      const templateStore = useTemplateStore();
      let dependentVariables = VariableHelpers.getDependentVariables(variable.name, templateStore.combinedVariables);
      return axios
        .post('editor/variables/format?language=' + templateStore.template.language, {
          dependentVariables,
          variable,
          formats,
        })
        .then((result) => result.data);
    },

    getSynonymFormatExamples({ synonym, formats, all }) {
      const templateStore = useTemplateStore();
      return axios
        .post('editor/synonyms/format?language=' + templateStore.template.language + (all ? '&all=true' : ''), {
          synonym,
          formats,
        })
        .then((result) => result.data);
    },

    setOpenInlineEditorMenu(value) {
      this.inlineEditorMenuOpen = value;
    },

    // AI Calls

    getSynonymSuggestions(synonym) {
      const templateStore = useTemplateStore();
      const data = {
        synonym: synonym,
        amount: this.synonymSuggestionsAmount,
        templateLanguage: templateStore.template.language,
      };
      return axios.post('editor/synonymsuggestions', data).then((res) => res.data);
    },

    translateAISynonyms(data) {
      return axios.post('editor/synonymtranslation', data).then((res) => res.data);
    },

    AIRephraseVariant(data) {
      return new Promise((resolve, reject) => {
        // commit("setLoadingAiVariantStatus", true)
        const templateStore = useTemplateStore();
        const gsynInstancesInVariant = SynonymHelpers.globalSynonymsInText(data.variantText).map((g) =>
          SynonymHelpers.getGsynName(g)
        );
        (data.amount = this.variantRephraseAmounts),
          (data.globalSynonyms = templateStore.template.globalSynonyms.filter((g) =>
            gsynInstancesInVariant.includes(g.name)
          ));
        data.dependentVariables = VariableHelpers.getDependentVariables(
          data.variantText,
          templateStore.combinedVariables
        ).reverse();
        data.templateLanguage = templateStore.template.language;

        axios
          .post('editor/variantsuggestions', data)
          .then((res) => {
            resolve(res.data.variants);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    AITranslateVariant(data) {
      const templateStore = useTemplateStore();
      const gsynInstancesInVariant = SynonymHelpers.globalSynonymsInText(data.variantText).map((g) =>
        SynonymHelpers.getGsynName(g)
      );
      data.translationLanguage = templateStore.AITranslationLanguage;
      data.globalSynonyms = templateStore.template.globalSynonyms.filter((g) =>
        gsynInstancesInVariant.includes(g.name)
      );
      data.dependentVariables = VariableHelpers.getDependentVariables(
        data.variantText,
        templateStore.combinedVariables
      ).reverse();
      data.templateLanguage = templateStore.template.language;

      return new Promise((resolve, reject) => {
        axios
          .post('editor/varianttranslation', data)
          .then((res) => {
            console.log(res);
            resolve(res.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    getFavorites() {
      const STORAGE_KEY = `template-${useTemplateStore().template.metadata.id}-favorites`;
      this.dataFavorites = JSON.parse(localStorage.getItem(STORAGE_KEY)) || [];
    },

    toggleFavorite(dataSet) {
      const STORAGE_KEY = `template-${useTemplateStore().template.metadata.id}-favorites`;
      if (this.dataFavorites.some((f) => f.id == dataSet.id)) {
        this.dataFavorites = this.dataFavorites.filter((f) => f.id != dataSet.id);
      } else {
        this.dataFavorites = [...this.dataFavorites, dataSet];
      }
      localStorage.setItem(STORAGE_KEY, JSON.stringify(this.dataFavorites));
    },
  },
});
