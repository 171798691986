import uniqueId from 'lodash/uniqueId';
export default class Snackbar {
  constructor(message) {
    this.id = uniqueId();
    this.message = message.message;
    this.type = message.type ?? 'info';
    this.icon = message.icon ?? false;
    this.closeBtn = message.closeBtn ?? true;
    this.actionText = message.actionText ?? null;
    this.action = message.action ?? null;
    this.timeVisible = message.timeVisible ?? 3000;
    this.visible = true;
  }

  get element() {
    return { ...this, iconClass: this.iconClass, iconStyling: this.iconStyling };
  }

  get iconClass() {
    switch (this.type) {
      case 'success':
        return 'fa-check-circle';
      case 'error':
        return 'fa-exclamation-circle';
      case 'warning':
        return 'fa-exclamation-triangle';
      case 'info':
        return 'fa-info-circle';
      default:
        return 'fa-info-circle';
    }
  }

  get iconStyling() {
    switch (this.type) {
      case 'success':
        return ['text-success'];
      case 'error':
        return ['text-danger80'];
      case 'warning':
        return ['text-orangeWeb80'];
      case 'info':
        return ['text-white'];
      default:
        return ['text-white'];
    }
  }
}





// Inject the newMessage function to show snackbar messages
// const newMessage = inject('newMessage');

// Example init newMessage
// newMessage({
//   message: "This is an error",
//   type: 'error',
//   icon: true,
//   action: () => {alert('action')},
//   actionText: 'Undo',
//   timeVisible: 60000,
// });
