<template>
  <!-- only wrapped in two divs so darkmode is the most outer class -->
  <div id="app" :class="{ dark: darkMode } " >
    <div class="flex dark:bg-darkbg" :class="showSideMenu ? 'bg-bgWhite min-h-screen' : ''">
      <SideMenu v-if="showSideMenu" />
      <RouterView :class="showSideMenu ? 'pl-[204px]' : ''" />
      <!-- {{ showSideMenu }}
      <span>{{ route.name }}</span> -->
    </div>
    <global-snackbars ref="snackbarRef" />
  </div>
</template>
<script setup>
import SideMenu from '@/components/SideMenu.vue';
import { RouterView, useRoute } from 'vue-router';
import { ref, onMounted, defineProps, computed, defineEmits, provide } from 'vue';
const route = useRoute();
const showSideMenu = computed(() => {
  return !['template', 'login', 'signup', 'reset-password', 'forgot-password', 'demo', 'demo.signup'].includes(
    route.name
  );
});
const darkMode = computed(() => {
  return false;
});

const snackbarRef = ref();
const newMessage = (message) => {
  snackbarRef.value.newMessage(message);
};
provide('newMessage', newMessage);
</script>
<style>
.NOTIMPLEMENTED {
  border: 1px solid red;
}
.scrollable-active-element {
  max-height: calc(100vh - 40px);
  padding-bottom: 24px;
}

.scrollable-active-element::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollable-active-element {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* *,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
  font-weight: normal;
  font-family: Roboto, sans-serif;
} */
/* @font-face {
  font-family: '3954';
  src: url('./fonts/futura/3954.eot');
  src: local('3954'), url('./fonts/futura/3954.woff') format('woff'), url('./fonts/futura/3954.ttf') format('truetype');
} */
/* h1,
h2,
h3,
h4 {
  font-family: 'Open Sans', sans-serif;
  
  
} */

.ProseMirror.ProseMirror-focused {
  outline: none;
}
.ProseMirror:focus {
  outline: none;
}

.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translate(200px, 0);
}

.fade-leave-active {
  position: absolute;
}
</style>
