import uniqueId from 'lodash/uniqueId'
import ConditionList from "./conditionList";

export default class Condition {
    constructor (condition, parent) {
        this.expression = condition.expression || ''
        this.conditionOperator = condition.conditionOperator || 'Or'
        this.subconditions = new ConditionList(condition.subConditions || [], this)
        this.getParent = () => parent
        this.id = uniqueId()
    }

    addSubcondition () {
        if (this.subconditions.empty) {
            this.subconditions.conditions.push(new Condition(this.export(), this))
            this.conditionOperator = 'Or'
            this.expression = ''
        }
        this.subconditions.addNewCondition(this.subconditions.conditions.length)
    }

    onUpdated () {
        if (this.getParent()) this.getParent().onUpdated()
    }

    cloneAsSubCondition(){
        let expression; 
        if(this.subconditions.empty){
            this.subconditions.conditions.push(new Condition(this.export(), this))
            this.conditionOperator = 'Or'
            expression = this.expression
            this.expression = ''
        } else{
            expression = this.subconditions.conditions[this.subconditions.conditions.length - 1].expression
        }
        this.subconditions.addNewCondition(this.subconditions.conditions.length, expression)
    }
    
    clone () {
        return new Condition(this.export(), this)
    }

    export () {
        let exportable = {}
        if (!this.subconditions.empty) { 
            exportable.subConditions = this.subconditions.export()
            exportable.conditionOperator = this.conditionOperator
        }
        else exportable.expression = this.expression
        return exportable
    }
}