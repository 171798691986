import { defineStore } from 'pinia';
import Template from '@/assets/js/template/template.js';
import Sentence from '@/assets/js/template/sentence.js';
import temporaryTemplate from '@/assets/js/setupTemplate.json';
import axios from 'axios';
import { useEditorStore } from '@/stores/editor';
import VariableHelper from '@/assets/js/helpers/variableHelper.js';
import VariableGroup from '@/assets/js/variableGroup.js';
import appHelper from '@/assets/js/appHelper.js';

export const useTemplateStore = defineStore({
  id: 'template',
  state: () => ({
    template: new Template(temporaryTemplate),
    templateBackup: null,
    templateBackupInfo: null,
    showingTemplateBackup: false,
    templateVariables: [],
    templateSectionBeingEdited: null,
    templateSectionTypeBeingEdited: null,
    activePath: [],
    activePathParent: null,
    activeSentence: null,
    activeGroup: null,
    activeGroupIndex: null,
    activeSection: false,
    unsavedChanges: false,
    templates: [],
    templateDataSearchResults: [],
    noSectionsInTemplate: false,
    dragScenarioGroupStarted: false,
    dragScenarioStarted: false,
    AITranslationLanguage: 'English',
    templateBackups: [],
  }),
  getters: {
    variableGroups: (getters) => new VariableGroup(VariableHelper.groupVariables(getters.combinedVariables)),
    combinedVariables: (state) => {
      if (!state.template || !state.templateVariables) return {};
      let vars = [
        ...(state.templateVariables || []),
        ...(state.template.extraVariables.map((v) => ({
          custom: true,
          type: 'Bool',
          ...v,
        })) || []),
      ].reduce((vars, v) => {
        vars[v.name] = v;
        return vars;
      }, {});

      return vars;
    },
    combinedVariableList: (state) => {
      if (!state.template || !state.templateVariables) return [];
      return [
        ...(state.templateVariables || []),
        ...(state.template.extraVariables.map((v) => ({
          displayName: VariableHelper.extractPrettyNameVariableOnly(v),
          custom: true,
          type: 'Bool',
          ...v,
        })) || []),
      ];
    },

    customVariableNames: (state) => {
      if (!state.template || !state.templateVariables) return [];
      return state.template.extraVariables.map((v) => VariableHelper.removeBrackets(v.name));
    },
  },
  actions: {
    // Drag and drop stuff
    cloneScenarioGroup(data) {
      console.log(data)
      // where the original scenario group is
      const sentenceList = this.templateSectionBeingEdited.findSentenceListById(data.draggedSentenceListId);
      console.log(sentenceList)
      // New sentence list destination for the scenario group - need to find this one in order to add it as the parent for the scenariogroup
      const newSentenceList = this.templateSectionBeingEdited.findSentenceListById(data.newSentenceListId);
      // clone the scenario group
      let clone = sentenceList.cloneScenarioGroup(data.number, newSentenceList);
      if (!data.clone) {
        console.log('Remove original scenario group');
        sentenceList.removeScenarioGroupByNumber(data.number);
      }
      clone.forEach((s) => {
        s.number = data.newNumber;
        newSentenceList.sentences.push(s);
      });
      this.templateSectionBeingEdited.setPaths([]);
      this.templateChange();
    },

    addScenarioToNewList(data) {
      const sentenceList = this.templateSectionBeingEdited.findSentenceListById(data.newSentenceListId);
      sentenceList.sentences.push(data.scenario);
    },

    removeScenarioGroup(data) {
      this.templateSectionBeingEdited
        .findSentenceListById(data.sentenceListId)
        .removeScenarioGroupByNumber(data.number);
    },

    findSentenceInSection(sentenceListId, sentenceId) {
      const sentenceList = this.templateSectionBeingEdited.findSentenceListById(sentenceListId);
      return sentenceList.getById(sentenceId);
    },

    removeScenario(data) {
      this.templateSectionBeingEdited.findSentenceListById(data.sentenceListId).removeById(data.sentenceId);
    },

    setActiveGroupById(id, number) {
      const group = this.templateSectionBeingEdited.findSentenceListById(id);
      if (!group) return;
      this.resetActiveElements();
      this.activeGroup = group.sentences.filter((g) => g.number == number - 1);
    },

    setActiveSentenceById(id) {},

    setActiveSentence(sentence) {
      console.log(sentence);
      this.resetActiveElements();
      this.activeSentence = sentence;
      this.setActivePath(sentence.path);
    },
    
    setActiveSentenceFromPath(path) {
      this.resetActiveElements();
      this.activeSentence = this.templateSectionBeingEdited.findSentenceByPath(path);
      this.setActivePath(path);
    },

    setActiveGroup(group, groupIndex) {
      console.log(group, groupIndex)
      this.resetActiveElements();
      this.activeGroup = group;
      this.activeGroupIndex = groupIndex;
      this.setActivePath(group[0].path);

    },
    resetActiveElements() {
      this.activeSentence = null;
      this.activeGroup = null;
      this.activeSection = false;
      this.activeGroupIndex = null;
    },
    setActiveSection() {
      this.resetActiveElements();
      this.activeSection = true;
    },

    setTemplateSectionBeingEdited(id, backup = this.showingTemplateBackup) {
      // const setSection = (id) => {
      //   return new Promise((resolve, reject) => {
      //     this.resetActiveElements();
      //     this.templateSectionBeingEdited = this.template.sections.find((s) => s.id == id);
      //     this.templateSectionTypeBeingEdited = 'text';
      //     resolve();
      //   });
      // };
      this.resetActiveElements();
      this.templateSectionBeingEdited = backup
        ? this.templateBackup?.sections?.find((s) => s.id == id)
        : this.template.sections.find((s) => s.id == id);
      this.templateSectionTypeBeingEdited = 'text';
      // try avoiding the js violation warnings and errors
      // setSection(id).then(() => {
      //   this.templateSectionBeingEdited.setPaths([]);
      // });
      // // this.templateSectionBeingEdited.setPathsLazy([]);
    },

    setAiSectionBeingEdited(id) {
      this.resetActiveElements();
      this.templateSectionBeingEdited = this.template.sections.find((s) => s.id == id);
      this.templateSectionTypeBeingEdited = 'ai';
    },

    editSectionName(sectionId, newName) {
      this.template.sections.find((s) => s.id == sectionId).name = newName;
    },

    editSectionId(sectionId, newId) {
      this.template.sections.find((s) => s.id == sectionId).id = newId;
    },

    toggleSectionValue(sectionId, key, value) {
      this.template.sections.find((s) => s.id == sectionId)[key] = value;
    },

    templateChange() {
      this.unsavedChanges = true;
    },

    saveTemplate() {
      return axios.put(`templates/${this.template.metadata.id}`, this.template.export()).then(() => {
        this.unsavedChanges = false;

        // update the metadata of the template primarily for the last updated date
        this.getTemplate(this.template.metadata.id).then((template) => {
          this.template.metadata = template.metadata;
        });
      });
    },

    publishTemplate({ publicationName, template, existingPublication }) {
      console.log('publishing template', template, existingPublication);
      return axios[existingPublication ? 'put' : 'post'](
        'templates/' +
          template.id +
          '/publish' +
          (existingPublication ? '/' + existingPublication.id : '?name=' + publicationName.trim())
      ).then((result) => {
        if (existingPublication) {
          this.templates.splice(
            this.templates.findIndex((t) => t.id === existingPublication.id),
            1,
            result.data
          );
        } else this.templates.push(result.data);
        return result.data;
      });
    },

    setTemplateData({ template, variables }) {
      this.template = new Template(template);
      this.templateVariables = variables;
    },

    getTemplates() {
      return axios.get('templates/metadata').then((res) => {
        this.templates = res.data;
      });
    },

    getTemplate(templateId) {
      return axios.get(`templates/${templateId}`).then((result) => result.data);
    },

    getVariables({ template, id, isDemo }) {
      return axios
        .get(
          `templates/${isDemo ? template.metadata.id : template.id}/metadata/variables` +
            (id ? `?contentId=${encodeURIComponent(id)}` : '')
        )
        .then((result) => result.data);
    },

    searchVariables({ templateId, query }) {
      return axios
        .get(`templates/${templateId}/search?pageSize=10&query=${encodeURIComponent(query)}`)
        .then((result) => (this.templateDataSearchResults = result.data));
    },

    getTemplateData(templateId) {
      const editorStore = useEditorStore();
      return new Promise((resolve, reject) => {
        let template = this.templates.find((t) => t.id == templateId);
        if (!template) {
          reject('notfound');
        } else {
          Promise.all([this.getTemplate(template.id), this.getVariables({ template })])
            .then((results) => {
              this.setTemplateData({ template: results[0], variables: results[1] });
              editorStore
                .getTemplatePreview({ template: this.template, variables: this.templateVariables })
                .then((preview) => {
                  editorStore.getTemplatePreviewForSectionType('ai');
                  resolve(preview);
                });
              editorStore.getVariableFormats({ templateLanguage: results[0].language });

              this.getTemplateBackups(templateId);
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    },

    setActivePath(path) {
      this.activePath = path;
    },

    setActivePathParent(sentenceList) {
      this.activePathParent = sentenceList;
    },

    updateActiveGroupView(sentenceListId, number) {
      // only update if the view is already active
      if (!this.activeGroup || this.activeGroup[0].getParent().id !== sentenceListId) return;
      const parentSentenceList = this.templateSectionBeingEdited.findSentenceListById(sentenceListId);
      this.activeGroup = parentSentenceList.sentences.filter((g) => g.number == number);
    },

    setAITranslationLanguage() {
      this.AITranslationLanguage =
        localStorage.getItem(`ai-translation-language-${this.template.metadata.id}`) ?? this.template.language;
    },

    getTemplateBackups(templateId) {
      this.templateBackups = [];
      return axios.get(`templates/${templateId}/backups`).then((result) => {
        this.templateBackups = result.data;
      });
    },

    getBackupInfo(backup) {
      const editorStore = useEditorStore();
      return new Promise((resolve, reject) => {
        axios
          .get(`templates/${this.template.metadata.id}/backups/${backup.id}`)
          .then((result) => {
            this.templateBackup = new Template({ ...result.data });
            this.templateBackupInfo = backup;
            this.showingTemplateBackup = true;
            editorStore.setReadOnlyMode(true);
            this.setTemplateSectionBeingEdited(this.templateBackup.sections?.[0].id, true);
            resolve(true);
          })
          .catch((error) => {
            this.templateBackup = null;
            this.templateBackupInfo = null;
            this.showingTemplateBackup = false;
            editorStore.setReadOnlyMode(false);
            reject(error);
          });
      });
    },

    revertTemplate(backup) {
      return new Promise((resolve, reject) => {
        axios
          .put(`templates/${this.template.metadata.id}/backups/${backup.id}/revert`)
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    resetTemplateBackupState() {
      this.setTemplateSectionBeingEdited(this.templateBackup.sections?.[0].id, false);
      const editorStore = useEditorStore();
      this.templateBackup = null;
      this.templateBackupInfo = null;
      this.showingTemplateBackup = false;
      editorStore.setReadOnlyMode(false);
    },

    // Template edit Actions
    replaceTextByRegex(data) {
      this.template.replaceByRegex(data.query, data.replacement, data.pattern);
    },
    replaceVariablesInConditions(data) {
      this.template.replaceInAllConditions(data.query, data.replacement, data.pattern);
    },
  },
});
