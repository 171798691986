import { createRouter, createWebHistory } from 'vue-router';
// import HomeView from '../views/HomeView.vue';
import { useTemplateStore } from '@/stores/template';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/HomeView.vue'),
      beforeEnter: (to, from, next) => {
        const store = useTemplateStore();
        store.getTemplates().then(() => {
          next();
        });
      },
    },
    {
      path: '/data',
      name: 'data',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/IntegrationView.vue'),
    },
    {
      path: '/templates/:templateId?',
      name: 'template',
      component: () => import('../views/EditorView.vue'),
      beforeEnter: (to, from, next) => {
        const store = useTemplateStore();
        if (store.templates.length == 0) {
          store.getTemplates().then(() => {
            next();
          });
        } else next();
      }
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('../views/SettingsView.vue'),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/LoginView.vue'),
    },
  ],
});

export default router;
