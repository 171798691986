import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import uniqueId from 'lodash/uniqueId';
import Sentence from '@/assets/js/template/sentence.js';
import VariantList from './variantList';
// import i18n from "./../../i18n";
export default class Sentences {
  constructor(sentences, parent) {
    this.sentences = this.sort((sentences || []).map((s) => new Sentence(s, this)));
    this.path = null; // path will be set by the parent
    this.getParent = () => parent;
    this.id = uniqueId();
  }

  get scenarioGroups() {
    return groupBy(this.sentences, 'number');
  }

  get empty() {
    return this.sentences.length === 0;
  }

  sort(sentences) {
    return orderBy(sentences, ['number', 'priority']);
  }

  export() {
    return this.sentences.map((s) => s.export());
  }

  setPaths(path) {
    this.path = path;
    this.sentences.forEach((sentence, index) => sentence.setPath(path.concat([index])));
  }

  setPathsLazy(path) {
    this.path = path;
    this.sentences.forEach((sentence, index) => sentence.setPathsLazy(path.concat([index])));
  }

  refreshPathsLazy() {
    this.setPathsLazy(this.path);
  }

  refreshPaths() {
    this.setPaths(this.path);
  }

  getScenarioGroup(scenarioGroupNumber) {
    return this.sentences.filter((s) => s.number == scenarioGroupNumber);
  }

  newScenariGroupNumber(scenarioGroupNumber, newNumber) {
    this.getScenarioGroup(scenarioGroupNumber).forEach((s) => {
      s.number = newNumber;
    });
    this.sentences = this.sort(this.sentences);
  }

  exportScenarioGroup(scenarioGroupNumber) {
    return this.getScenarioGroup(scenarioGroupNumber).map((s) => s.export());
  }

  cloneScenario(scenario, newParent = null) {
    const parent = newParent ?? this;
    return new Sentence(scenario.export(), parent);
  }

  cloneScenarioGroup(scenarioGroupNumber, newParent = null) {
    const parent = newParent ?? this;
    return this.exportScenarioGroup(scenarioGroupNumber).map((s) => new Sentence(s, parent));
  }

  cloneScenarioGroupAfter(scenarioGroupNumber) {
    let nextNumber = parseInt(scenarioGroupNumber) + 1;
    let scenarioGroupClone = this.cloneScenarioGroup(scenarioGroupNumber);

    if (this.sentences.some((s) => s.number == nextNumber)) {
      this.sentences.forEach((s) => {
        if (s.number >= nextNumber) s.number = s.number + 1;
      });
    }
    scenarioGroupClone.forEach((s) => {
      s.number = nextNumber;
      this.sentences.push(s);
    });
    this.sentences = this.sort(this.sentences);
    this.refreshPaths();
    console.log(this);
    console.log(scenarioGroupClone);
    return scenarioGroupClone;
  }

  clone() {
    return new Sentences(this.export());
  }

  getById(sentenceId) {
    return this.sentences[this.getIndex(sentenceId)];
  }

  getIndex(sentenceId) {
    return this.sentences.map((s) => s.id).indexOf(sentenceId);
  }

  removeById(sentenceId) {
    let index = this.getIndex(sentenceId);
    if (index > -1) this.sentences.splice(this.getIndex(sentenceId), 1);
    else console.log('Attempted to remove a non-existent sentence with id:' + sentenceId);
  }

  // removes a scenario group with the provided number
  removeScenarioGroupByNumber(number) {
    this.sentences = this.sentences.filter((s) => s.number != number);
    this.sentences = this.sort(this.sentences);
  }

  getTextContent(sentenceType) {
    switch (sentenceType) {
      case 'Sectionbreak':
        return ['<br><br>'];
      case 'Linebreak':
        return ['<br>'];
      case 'NewParagraph':
        return ['</p><p>'];
      // case "TableCell" : return [i18n.t("tables.table-cell"), i18n.t("tables.table-cell2")];
      // case "TableHeaderCell" : return [i18n.t("tables.table-header-cell"), i18n.t("tables.table-header-cell2")];
      case 'TableCell':
        return ['Variant one of table cell', 'Variant two of table cell'];
      case 'TableHeaderCell':
        return ['Variant one of table header cell', 'Variant two of table header cell'];
      case 'TableHeaderRow':
        return null;
      case 'TableRow':
        return null;
      case 'Table':
        return null;
      case 'UnorderedList':
        return null;
      case 'OrderedList':
        return null;
      case 'ListItem':
        return [''];
      default:
        return [''];
    }
  }
  newSentenceElement(number, priority, sentenceType, name = null, parent = null, tableColumnCount = null) {
    return new Sentence(
      {
        name: name,
        conditions: [],
        number: parseInt(number),
        priority: parseInt(priority),
        texts: this.getTextContent(sentenceType),
        sentenceType: sentenceType,
        tableColumnCount: tableColumnCount,
      },
      parent
    );
  }

  addSentence(number, priority, sentenceType, name = null, parent = this) {
    // create the new sentence
    let newSentence = this.newSentenceElement(number, priority, sentenceType, name, parent);
    this.sentences.push(newSentence);
    // then sort
    this.sentences = this.sort(this.sentences);
    this.refreshPaths();
    // this.onUpdated()
    // finally set the path of the sentence
    // newSentence.setPath(this.path.concat([this.getIndex(newSentence.id)]))
    return newSentence;
  }

  checkGroupedPriority(number, priority) {
    let groupedScenarios = this.sentences.filter((s) => parseInt(s.number) == number && s.priority == priority);
    let indexes = [];
    groupedScenarios.forEach((s) => {
      indexes.push(this.getIndex(s.id));
    });
    return Math.min(...indexes);
  }

  prioritizeScenariosInSentence(number, scenarioPriority, movingDown) {
    scenarioPriority = parseInt(scenarioPriority);
    if (movingDown) {
      scenarioPriority = scenarioPriority + 1;
      this.scenarioGroups[number].forEach((s) => {
        if (s.priority >= scenarioPriority) s.priority = s.priority + 1;
      });
    } else {
      // if the previous number is above 0, and there are no sentences with that number, just create the sentence using that number.
      if (scenarioPriority > 0 && !this.sentences.some((s) => s.priority == scenarioPriority - 1)) {
        return scenarioPriority - 1;
      }
      // else we need to bump the sentences with the next numbers up, to make room for the new sentence number
      let recursivelyBumpSentencePriorityIfExists = (sentencePriorityToBump) => {
        let sentencesWithPriorityToBump = this.sentences.filter(
          (s) => parseInt(s.number) == number && s.priority == sentencePriorityToBump
        );
        if (sentencesWithPriorityToBump.length > 0) {
          // since there are numbers to bump, make sure to bump any following sentencenumbers first, recursively.
          recursivelyBumpSentencePriorityIfExists(sentencePriorityToBump + 1);
          // now bump it
          sentencesWithPriorityToBump.forEach((s) => (s.priority = parseInt(s.priority) + 1));
        }
      };
      // if a sentence with the target number exists, bump up the number for it and all following sentences, until the next number is free.
      recursivelyBumpSentencePriorityIfExists(scenarioPriority);
    }

    return scenarioPriority;
  }

  prioritizeAsAbove(sentence) {
    const priorities = this.getPrioritiesInGroup(sentence.number)
    const previousPriorities = priorities.filter((p) => p < sentence.priority);
    const closestPriority = previousPriorities.length ? Math.max(...previousPriorities) : sentence.priority;
    sentence.priority = closestPriority;
    return closestPriority;
  }

  prioritizeAsBelow(sentence) {
    const priorities = this.getPrioritiesInGroup(sentence.number)
    const nextPriorities = priorities.filter((p) => p > sentence.priority);
    const closestPriority = nextPriorities.length ? Math.min(...nextPriorities) : sentence.priority;
    sentence.priority = closestPriority;
    return closestPriority;
  }

  getPrioritiesInGroup(sentenceNumber){
    return this.scenarioGroups[sentenceNumber].map((s) => s.priority);
  }

  // hvis indsæt over 0, så skub alle med 0 op
  createScenarioGroupBefore(number, sentenceType) {
    let newNumber = this.bumpSentencesIfNecessary(number);
    return this.addSentence(newNumber, 1, sentenceType);
  }

  bumpSentencesIfNecessary(number) {
    number = parseInt(number);
    // if the previous number is above 0, and there are no sentences with that number, just create the sentence using that number.
    if (number > 0 && !this.sentences.some((s) => s.number == number - 1)) {
      // return existingSentenceGroup ? this.addExistingSentenceGroup(number, existingSentenceGroup) : this.addSentence(number - 1, 1, sentenceType);
      return number - 1;
    }

    // else we need to bump the sentences with the next numbers up, to make room for the new sentence number
    let recursivelyBumpSentenceNumberIfExists = (sentenceNumberToBump) => {
      let sentencesWithNumberToBump = this.sentences.filter((s) => parseInt(s.number) == sentenceNumberToBump);
      if (sentencesWithNumberToBump.length > 0) {
        // since there are numbers to bump, make sure to bump any following sentencenumbers first, recursively.
        recursivelyBumpSentenceNumberIfExists(sentenceNumberToBump + 1);
        // now bump it
        sentencesWithNumberToBump.forEach((s) => (s.number = parseInt(s.number) + 1));
      }
    };

    // if a sentence with the target number exists, bump up the number for it and all following sentences, until the next number is free.
    recursivelyBumpSentenceNumberIfExists(number);

    return number;
  }

  createScenarioGroupAfter(number, sentenceType) {
    let nextNumber = parseInt(number) + 1;
    // if sentences with the number of number + 1 already exists, bump them all up 1. This makes room for the scenariogroup with the next number.
    if (this.sentences.some((s) => s.number == nextNumber)) {
      this.sentences.forEach((s) => {
        if (s.number >= nextNumber) s.number = s.number + 1;
      });
    }
    // now insert the next sentence with the next number
    return this.addSentence(nextNumber, 1, sentenceType);
  }

  createTableBefore(number, sentenceType, columns, rows, applyTableHeadRow, applyTableHeadColumn) {
    const newNumber = this.bumpSentencesIfNecessary(number);
    return this.createTableBase(newNumber, sentenceType, columns, rows, applyTableHeadRow, applyTableHeadColumn);
  }

  createTableAfter(number, sentenceType, columns, rows, applyTableHeadRow, applyTableHeadColumn) {
    const newNumber = this.increaseNumbersIfNecessary(number);
    return this.createTableBase(newNumber, sentenceType, columns, rows, applyTableHeadRow, applyTableHeadColumn);
  }

  createTableBelow(columns, rows, applyTableHeadRow, applyTableHeadColumn) {
    let nextNumber = Math.max(...this.sentences.map((s) => s.number || 0)) + 1;
    if (nextNumber == -Infinity) nextNumber = 0;
    return this.createTableBase(nextNumber, 'Table', columns, rows, applyTableHeadRow, applyTableHeadColumn);
  }

  createTableBase(number, sentenceType, columns, rows, applyTableHeadRow, applyTableHeadColumn, priority = 1) {
    const rowNumber = applyTableHeadRow ? 1 : 0;
    // i18n.t("tables.table")
    const tableBase = this.addSentence(number, priority, sentenceType, null);
    const rowsInTable = [];

    if (applyTableHeadRow) {
      // i18n.t("tables.table-header")
      const tableHeaderRow = this.newSentenceElement(0, 1, 'TableHeaderRow', null, tableBase, columns);
      const tableHeaderColumns = this.addTableColumnsForRow(columns, tableHeaderRow, 'TableHeaderCell');
      tableHeaderRow.addSentences(tableHeaderColumns);
      rowsInTable.push(tableHeaderRow);
    }

    for (let i = 0; i < parseInt(rows - rowNumber); i++) {
      // const rowName = `${i18n.t("tables.row")} ${parseInt(i+1+rowNumber)}`
      const rowName = null;
      const newRow = this.newSentenceElement(parseInt(i + rowNumber), 1, 'TableRow', rowName, tableBase, columns);
      const columnsInRow = this.addTableColumnsForRow(columns, newRow, 'TableCell', applyTableHeadColumn);
      newRow.addSentences(columnsInRow);
      rowsInTable.push(newRow);
    }
    tableBase.addSentences(rowsInTable);
    return tableBase;
  }

  createListBefore(number, sentenceType, priority) {
    const newNumber = this.bumpSentencesIfNecessary(number);
    return this.createList(newNumber, sentenceType);
  }

  createListAfter(number, sentenceType, priority) {
    const newNumber = this.increaseNumbersIfNecessary(number);
    this.createList(newNumber, sentenceType);
  }

  createListBelow(sentenceType) {
    let nextNumber = Math.max(...this.sentences.map((s) => s.number || 0)) + 1;
    if (nextNumber == -Infinity) nextNumber = 0;
    return this.createList(nextNumber, sentenceType);
  }

  createList(number, sentenceType, priority = 1) {
    const listBase = this.addSentence(number, priority, sentenceType);
    const firstListItem = this.newSentenceElement(0, 1, 'ListItem', null, listBase);
    listBase.addSentences([firstListItem]);
    return listBase;
  }

  addTableColumnsForRow(columns, parent, sentenceType = 'TableCell', applyTableHeadColumn) {
    const rowColumns = [];
    const columnNumber = applyTableHeadColumn ? 1 : 0;

    if (applyTableHeadColumn) {
      const headerColumn = this.newSentenceElement(0, 1, 'TableHeaderCell', null, parent, columns);
      rowColumns.push(headerColumn);
    }

    for (let i = 0; i < parseInt(columns - columnNumber); i++) {
      // const columnName = `${i18n.t("tables.column")} ${parseInt(i+1)}`
      const columnName = null;
      const newColumn = this.newSentenceElement(i + columnNumber, 1, sentenceType, columnName, parent, columns);
      rowColumns.push(newColumn);
    }
    return rowColumns;
  }
  increaseNumbersIfNecessary(number) {
    let nextNumber = parseInt(number) + 1;
    // if sentences with the number of number + 1 already exists, bump them all up 1. This makes room for the scenariogroup with the next number.
    if (this.sentences.some((s) => s.number == nextNumber)) {
      this.sentences.forEach((s) => {
        if (s.number >= nextNumber) s.number = s.number + 1;
      });
    }
    return nextNumber;
  }
  addNewRowAfter(number, sentenceType, columns) {
    const nextNumber = this.increaseNumbersIfNecessary(number);
    return this.addNewRow(nextNumber, 1, sentenceType, columns);
  }
  addNewRowBefore(number, sentenceType, columns) {
    const newNumber = this.bumpSentencesIfNecessary(number);
    return this.addNewRow(newNumber, 1, sentenceType, columns);
  }
  addNewRow(number, priority = 1, sentenceType = 'TableRow', columns) {
    if (!columns) columns = 1; // In case tableColumnCount is not set - then add at least one column

    const addingHeaderRow = this.sentences[0].sentenceType == 'TableHeaderRow' && number == 0;
    // const rowName = addingHeaderRow ? i18n.t("tables.table-header") : `${i18n.t("tables.row")} ${parseInt(number + 1)}`
    const rowName = null;
    const newRow = this.newSentenceElement(parseInt(number), priority, sentenceType, rowName, this, columns);
    const columnsInRow = this.addTableColumnsForRow(columns, newRow, addingHeaderRow ? 'TableHeaderCell' : 'TableCell');
    newRow.addSentences(columnsInRow);

    this.sentences.push(newRow);
    this.sentences = this.sort(this.sentences);
    this.refreshPaths();
    return newRow;
  }
  addNewCellAfter(number, sentenceType, columns) {
    const nextNumber = this.increaseNumbersIfNecessary(number);
    return this.addNewCell(nextNumber, 1, sentenceType, columns);
  }
  addNewCellBefore(number, sentenceType, columns) {
    const newNumber = this.bumpSentencesIfNecessary(number);
    return this.addNewCell(newNumber, 1, sentenceType, columns);
  }

  addNewCell(number, priority = 1, sentenceType = 'TableCell', columns) {
    if (!columns) columns = 1; // In case tableColumnCount is not set - then add at least one column
    // const columnName = `${i18n.t("tables.column")} ${parseInt(number+1)}`
    const columnName = null;
    const newCell = this.newSentenceElement(number, priority, sentenceType, columnName, this, columns);
    this.sentences.push(newCell);
    this.sentences = this.sort(this.sentences);
    this.refreshPaths();
    return newCell;
  }

  // create a new scenario with the provided priority. Bump up any existing with the provided priority or more
  createScenarioBefore(scenarioNumber, scenarioPriority) {
    this.scenarioGroups[scenarioNumber].forEach((s) => {
      if (s.priority >= scenarioPriority) s.priority = s.priority + 1;
    });
    return this.addSentence(scenarioNumber, scenarioPriority);
  }

  // create a new scenario with the provided priority + 1. Bump up any existing with the next priority or more
  createScenarioAfter(scenarioNumber, scenarioPriority) {
    let nextPriority = scenarioPriority + 1;
    this.scenarioGroups[scenarioNumber].forEach((s) => {
      if (s.priority >= nextPriority) s.priority = s.priority + 1;
    });
    return this.addSentence(scenarioNumber, nextPriority);
  }

  cloneScenarioAfter(scenario) {
    let nextPriority = scenario.priority + 1;
    this.scenarioGroups[scenario.number].forEach((s) => {
      if (s.priority >= nextPriority) s.priority = s.priority + 1;
    });
    let scenarioClone = new Sentence(scenario.export(), this);
    // insert
    this.sentences.push(scenarioClone);
    // then sort
    this.sentences = this.sort(this.sentences);
    this.refreshPaths();
    return scenarioClone;
  }

  moveScenario() {
    this.sentences = this.sort(this.sentences);
  }

  // deprecated - to be removed
  createSentenceAfter(sentence) {
    let indexBefore = this.getIndex(sentence.id);
    this.sentences.splice(
      indexBefore + 1,
      0,
      new Sentence(
        {
          conditions: [{ expression: '' }],
          number: sentence.number,
          priority: sentence.priority + 1,
          texts: [],
        },
        this
      )
    );
    this.onUpdated();
  }

  // deprecated - use addSentence
  addNewSentence(sentenceType) {
    let textContent = this.getTextContent(sentenceType);
    let nextNumber = Math.max(...this.sentences.map((s) => s.number || 0)) + 1;
    if (nextNumber == -Infinity) nextNumber = 0;
    let newSentence = new Sentence({ number: nextNumber, texts: textContent, sentenceType: sentenceType }, this);
    this.sentences.push(newSentence);
    this.onUpdated();
    return newSentence;
  }

  onUpdated(update) {
    let didClean = false;
    // didClean = this.clean()
    if (didClean && this.getParent()) this.getParent().onUpdated();
    else if (!didClean || (didClean && !this.getParent())) this.setPaths(this.getParent() ? this.path : []);
  }

  clear() {
    this.sentences = [];
  }

  clean() {
    console.log('cleaning');
    let changes = false;
    // go through all sentences backwards, in case we remove some
    for (var i = this.sentences.length - 1; i >= 0; i--) {
      let sentence = this.sentences[i];
      if (sentence.empty) this.removeById(sentence.id); // delete
    }

    Object.keys(this.scenarioGroups).forEach((number) => {
      let group = this.scenarioGroups[number];
      if (group.length == 1) {
        // clean up the sentence
        let sentence = group[0];
        if (
          sentence.sentenceList.sentences.length == 1 &&
          sentence.sentenceList.sentences[0].sentenceList.empty &&
          sentence.sentenceList.sentences[0].conditionList.empty
        ) {
          // only one subsentence in the sentence, with variants, and no conditions.
          sentence.variantList = new VariantList(sentence.sentenceList.sentences[0].variantList.export(), sentence);
          sentence.sentenceList.clear();
          changes = true;
        }
      } else if (group.length > 1 && group.every((s) => s.sentenceList.empty && s.conditionList.empty)) {
        // no conditions or subsentences in any of the sentences, no need for this to be split into subsentences
        // Instead, let's combine them all into just one sentence with variants
        let sentencesToCollapse = this.sentences.reduce((lookup, currentSentence, idx) => {
          // we have to go through all sentences to get the correct index in the full list
          if (currentSentence.number == number) {
            lookup[idx] = lookup[idx] || [];
            lookup[idx].splice(0, 0, ...currentSentence.variantList.variants.map((v) => v.text));
          }
          return lookup;
        }, {});
        let allVariants = Object.keys(sentencesToCollapse)
          .reverse()
          .reduce((variants, idx) => {
            this.sentences.splice(idx, 1);
            return variants.concat(sentencesToCollapse[idx].reverse());
          }, [])
          .reverse();
        // TODO: add priority
        this.sentences.push(new Sentence({ number: number, texts: allVariants, conditions: [] }, this));
        console.log(this.sentences);
        // TODO: what about current active path, if collapsed?
        changes = true;
      }
    });

    return changes;
  }
}
