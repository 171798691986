
export default {
  getPathsInPreveiwSection(section) {
    let paths = [];

    // base case
    const sentences = section?.result.sentences;
    if (!sentences) return [];

    
    const getPaths = (sentences) => {
      sentences?.forEach((s) => {
        if (s.text) paths.push(s.path);
        else {
            paths.push(s.path)
            getPaths(s.sentences)
        };
      });
    };

    getPaths(sentences);
    return paths;
  },
};
