import uniqueId from 'lodash/uniqueId'
// import Vue from 'vue'

export default class VariantText {
    constructor (text, sentence) {        
        this.text = text
        this.id = uniqueId()
        this.editorIsActive = false
        this.getParent = () => sentence
        this.path = null // path will be set by the parent
    }

    edit () {
        this.editorIsActive = true
    }

    stopEditing () {
        this.editorIsActive = false
    }

    setPath (path) {
        this.path = path
        // Vue.set(this, 'path', path)
    }

    remove () {
        this.getParent().remove(this)
    }

    export () {
        return this.text
   }
}