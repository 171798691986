import SentenceList from './sentenceList';
import Sentence from './sentence';
import Section from './section';
export default class TemplateSection extends Section{
    constructor(section) {
        super(section)
        this.sentences = new SentenceList(section.sentences)
        this.path = null,
        this.paragraph = section.paragraph || null
    }

    setPaths (path) {
        console.log("setPaths", path)
        this.path = path
        this.sentences.setPaths(path)
    }

    
    // need to set path lazy to avoid circular reference
    setPathsLazy (path) {
        this.path = path
        this.sentences.setPathsLazy(path)
    }


    clone () {
        return new TemplateSection(this.export())
    }

    export () {
        return { 
            ...this.exportBase(),
            sentences: this.sentences.export(),
            paragraph: this.paragraph
        }
    }

    search (query, matches) {
        this.sentences.sentences.forEach(sentence => {
            Sentence.findMatchesRecursively(sentence, query.toLowerCase(), matches)     
        })
        
        matches.forEach(match => {
            let re = new RegExp(query,"gi");
            match.highlight = match.text.replace(re, '<span class="highlight">' + match.text.substring(match.text.search(re), match.text.search(re) + query.length) + '</span>')
        })
        return matches
    }
    
    replaceSearch(query, matches){
        this.sentences.sentences.forEach(sentence => {
            Sentence.findMatchesRecursively(sentence, query.toLowerCase(), matches)     
        })        
    }
    findSentenceListById(id){
        if(this.sentences.id == id) {
            return this.sentences
        } 
        else {
            let match;
            this.sentences.sentences.forEach(sentence => {
                let sentenceList = Sentence.findSentenceListIdRecursively(sentence, id, 1)
                if(sentenceList && sentenceList.id == id){match = sentenceList}
            })
            return match
        }
    }

    
    replaceByRegex(query, replacement, pattern){
        this.sentences.sentences.forEach(sentence => {
            Sentence.replaceByRegex(sentence, query, [], replacement, pattern)   
        })        
    }
    transformTemplateSyn(synonym, pattern){
        this.sentences.sentences.forEach(sentence => {
            Sentence.transformSynonyms(sentence, synonym, pattern, [])   
        })
    }

    filterSentencesByPaths(query, matches){
        this.sentences.sentences.forEach(sentence => {
            Sentence.findMatchesRecursively(sentence, query.toLowerCase(), matches)     
        })
        
        matches.forEach(match => {
            let re = new RegExp(query,"gi");
            match.highlight = match.text.replace(re, '<span class="highlight">' + match.text.substring(match.text.search(re), match.text.search(re) + query.length) + '</span>')
        })

        // get filtered template based on paths from matches

        let filteredSentences = []
        
        matches.forEach(match => {
            console.log(match.getParent().id)
            let sentenceList = this.findSentenceListById(match.getParent().id)
            let sentence = sentenceList.findSentenceById(match.sentenceId)
            filteredSentences.push(sentence)
        })
        return matches
    }

    
    findSentenceByPath(path){
        let result = this.sentences.sentences[path[0]];
        path.slice(1).forEach((p) => {
            result = result.sentenceList.sentences[p] ?? result;
        });
        return result;
    }

    // findVariantById(id){
    //     console.log(id)

    //     this.sentences.sentences.forEach(sentence => {
    //         console.log(sentence) 
    //     })
    // }
}