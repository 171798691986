// import store from "@/store/store";

export default class AiOutputSettings {
  constructor(settings) {
    (this.characters = settings.characters || 100),
    (this.textType = settings.textType || "product"),
    (this.tone = settings.tone || "selling"),
    (this.language = this.setLanguage(settings.language)),
    (this.heading = settings.heading || ""),
    (this.sections = settings.sections || 1),
    (this.list = settings.list || "");
  }

  setLanguage(language) {
    if (language && language.length > 0) {
      return language;
    } 
    // else if (store.state.template && store.state.template.language) {
    //     return "English"
    // //   return ["", "Other"].includes(store.state.template.language) ? "English" : store.state.template.language;
    // }
     else return "English";
  }

  export() {
    return {
      characters: this.characters,
      textType: this.textType,
      tone: this.tone,
      language: this.language,
      heading: this.heading,
      sections: this.sections,
      list: this.list
    };
  }
}