import Sentence from '@/assets/js/template/sentence.js';
import TemplateSection from './templateSection';
import AiSection from './aiSection';
export default class Template {
  constructor(template) {
    this.language = template.language;
    this.metadata = template.metadata;
    this.extraVariables = template.extraVariables || [];
    this.methods = template.methods || [];
    this.globalSynonyms = template.globalSynonyms || [];
    this.images = template.images;
    this.sections = this.setTemplateSections(template.sections);
  }

  get templateSections(){
    return this.sections.filter((s) => s.type === "text" || !s.type);
  }

  get aiSections(){
    return this.sections.filter((s) => s.type === "ai");
  }

  setTemplateSections(sections) {
    return sections.map((s) => this.setSectionByType(s));
  }
  setSectionByType(section) {
    switch (section.type) {
      case 'text':
        return new TemplateSection(section);
      case 'ai':
        return new AiSection(section);
      default:
        return new TemplateSection(section);
    }
  }

  getSection(id) {
    return this.sections.find((s) => s.id === id);
  }

  getAllSectionNamesAndIds() {
    return this.sections.reduce(
      (all, current) => all.concat(Object.values({ name: current.name, id: current.id })),
      []
    );
  }

  getAllSentencesInSections() {
    // reduce only on template type sections
    return this.templateSections.reduce((all, current) => all.concat(current.sentences.sentences), []);
  }

  // addSection(id, name, index, paragraph = false, type = "text") {
  //   console.log(id, name, index, paragraph, type);
  //   if (index != undefined)
  //     this.sections.splice(
  //       index,
  //       0,
  //       this.setSectionByType({ id: id, name: name, sentences: [], paragraph: paragraph, type: type })
  //     );
  //   else
  //     this.sections.push(
  //       this.setSectionByType({ id: id, name: name, sentences: [], paragraph: paragraph, type: type })
  //     );
  // }

  addSection({id, name, index, paragraph = false, type = 'text'}) {
    let newSection;
    if (type == 'text') {
      newSection = {
        id: id,
        name: name,
        sentence: [],
        paragraph: paragraph,
        type: type,
        active: true,
      };
    } else {
      newSection = {
        id: id,
        name: name,
        type: type,
        active: false,
      };
    }
    if (index != undefined) this.sections.splice(index, 0, this.setSectionByType(newSection));
    else this.sections.push(this.setSectionByType(newSection));
  }

  duplicateSection({ sectionId, newId, newName, index }) {
    let duplicate = this.getSection(sectionId).clone();
    const duplicateId = newId ?? `${duplicate.id} (copy)`;
    const duplicateName = newName ?? `${duplicate.name} (copy)`;
    duplicate.id = duplicateId;
    duplicate.name = duplicateName;
    if (index != undefined) this.sections.splice(index, 0, duplicate);
    else this.sections.push(duplicate);
  }

  deleteSectionById(sectionId) {
    this.sections = this.sections.filter((s) => s.id != sectionId);
  }

  getSectionIndexById(sectionId) {
    return this.sections.findIndex((s) => s.id === sectionId);
  }

  export() {
    return {
      language: this.language,
      metadata: this.metadata,
      extraVariables: this.extraVariables,
      globalSynonyms: this.globalSynonyms,
      methods: this.methods,
      images: this.images,
      sections: this.sections.map((s) => s.export()),
    };
  }

  searchByRegex(pattern) {
    let matches = [];
    let allSentencesInSections = this.getAllSentencesInSections();
    allSentencesInSections.forEach((sentence) => {
      Sentence.findMatchesRecursivelyByRegex(sentence, pattern, matches);
    });
    return matches;
  }

  search(query, caseSensitive) {
    let matches = [];
    let allSentencesInSections = this.getAllSentencesInSections();
    allSentencesInSections.forEach((sentence) => {
      Sentence.findMatchesRecursively(sentence, query, matches, caseSensitive);
    });
    return matches;
  }
  replaceByRegex(query, replacement, pattern) {
    let matches = this.search(query);
    let allSentencesInSections = this.getAllSentencesInSections();
    allSentencesInSections.forEach((sentence) => {
      Sentence.replaceByRegex(sentence, query, matches, replacement, pattern);
    });
  }

  replaceInAllConditions(query, replacement, pattern) {
    let allSentencesInSections = this.getAllSentencesInSections();
    allSentencesInSections.forEach((sentence) => {
      Sentence.replaceVariablesInConditions(sentence, query, replacement, pattern);
    });
  }

  transformTemplateSyn(synonym, pattern) {
    let matches = [];
    let allSentencesInSections = this.getAllSentencesInSections();
    allSentencesInSections.forEach((sentence) => {
      Sentence.transformSynonyms(sentence, synonym, pattern, matches);
    });
  }

  dragAndDropSection(oldIndex, newIndex) {
    const section = this.sections[oldIndex];
    this.sections.splice(oldIndex, 1);
    this.sections.splice(newIndex, 0, section);
  }


  transformAllTemplateSyn() {
    // transform all template synonyms
    console.log('Transforming all template synonyms')
    let allSentencesInSections = this.getAllSentencesInSections();
    this.globalSynonyms.forEach((synonym) => {
      const pattern = new RegExp(":gsyn\\(" + synonym.name + "(\\|[^\\)]+)?\\)", "g");
      let matches = [];
      allSentencesInSections.forEach((sentence) => {
        Sentence.transformSynonyms(sentence, synonym, pattern, matches);
      });

      console.log(synonym.name, ' Transformed')
    });

    console.log('All template synonyms transformed')
  }
}
