<template>
  <div id="sideMenu" class="grow fixed w-[180px]">
    <header class="px-[18px] py-[20px]">
      <router-link :to="{ name: 'home' }">
        <img src="@/assets/svg/logo_horizontal.svg" alt="IC Robotics logo" class="h-[54px]" />
      </router-link>
    </header>

    <div class="grow flex flex-col gap-2 pl-[18px]">
      <router-link :to="{ name: 'home' }" class="menu-link group relative">
        <i class="fa-light fa-file"></i>
        <span class="">Layouts</span>
      </router-link>

      <router-link :to="{ name: 'data' }" class="menu-link group relative">
        <i class="fa-light fa-database"></i>
        <span class="">Integrations</span>
      </router-link>

      <router-link :to="{ name: 'settings' }" class="menu-link group relative">
        <i class="fa-light fa-cog"></i>
        <span class="">Settings</span>
      </router-link>

      <a class="menu-link group relative bg-flame20 text-flame60 cursor-pointer" @click="logOut()">
        <i class="fa-light fa-sign-out"></i>
        <span class="text-inherit">Log out</span>
      </a>
    </div>
    <!-- <div class="menu-link">
      <a href="https://help.icrobotics.com/" target="_blank">
        <i class="fa-light fa-circle-info"></i>
      </a>
    </div> -->
  </div>
</template>
<script setup>
import { useMainStore } from '@/stores/main.js';

const store = useMainStore();
const logOut = () => {
  console.log('logout now');
  store.logout();
};
</script>

<style></style>
