import VariantText from './variantText';
import uniqueId from 'lodash/uniqueId';
export default class Variants {
  constructor(variants, parent) {
    this.id = uniqueId();
    this.variants = (variants || []).map((v) => new VariantText(v, this));
    this.path = null; // path will be set by the parent
    this.getParent = () => parent;
  }

  get empty() {
    return this.variants.length === 0;
  }

  setPaths(path) {
    this.variants.forEach((variant, index) => variant.setPath(path.concat([index])));
  }

  setPathsLazy(path, setNext = false) {
    this.path = path;
    this.variants.forEach((variant, index) => variant.setPath(path.concat([index])));
  }

  export() {
    return this.variants.map((v) => v.export());
  }

  add(text, index) {
    let variant = new VariantText(text, this);
    if (index !== undefined) this.variants.splice(index, 0, variant);
    else this.variants.push(variant);
    this.onUpdated();
    return variant;
  }

  replace(text, index) {
    let variant = new VariantText(text, this);
    this.variants.splice(index, 1, variant);
    this.onUpdated();
    return variant;
  }

  remove(variant) {
    let indexToRemove = this.getVariantIndex(variant.id);
    this.variants.splice(indexToRemove, 1);
    this.getParent().onUpdated();
  }

  removeByIndex(index) {
    this.variants.splice(index, 1);
    // this.getParent().onUpdated();
  }

  clear() {
    this.variants = [];
  }

  getVariantIndex(id) {
    return this.variants.map((t) => t.id).indexOf(id);
  }

  onUpdated() {
    this.getParent().onUpdated();
  }

  addMultipleVariants(texts = [], index = 0) {
    const variantTexts = [];
    texts.forEach((t) => {
      variantTexts.push(new VariantText(t, this));
    });
    this.variants.splice(index, 0, ...variantTexts);
    this.onUpdated();
  }
}
