import { ref, computed, nextTick } from 'vue';
import { defineStore } from 'pinia';
import axios from 'axios';

// temporary actions and state in this - used for setup
export const useMainStore = defineStore({
  id: 'main',
  state: () => ({
    token: localStorage.getItem('token'),
    loadingRoute: false,
    organization: null,
    handlingExpiredToken: false,
  }),

  getters: () => ({}),
  actions: {
    logout() {
      localStorage.removeItem('token');
      this.token = null;
      this.$router.push('login');
      location.reload();
    },

    login(credentials, redirect = true) {
      return axios
        .post(
          'login',
          { username: credentials.email, password: credentials.password, grant_type: 'password' },
          { emulateJSON: true }
        )
        .then((res) => {
          console.log(res);
          localStorage.setItem('token', res.data);
          this.token = res.data;
          if (redirect) this.$router.push('/');
        });
    },

    handleExpiredToken() {
      if (!this.handlingExpiredToken) {
        this.handlingExpiredToken = true;
        console.log('Handling expired token');
      }
    },
  },
});
