export default class Section {
    constructor(section) {
      this.id = section.id;
      this.name = section.name;
      this.createdDate = section.createdDate || new Date();
      this.type = section.type;
      this.active = section.active;
    }
  
    exportBase() {
      return {
        id: this.id,
        name: this.name,
        createdDate: this.createdDate,
        type: this.type,
        active: this.active
      };
    }
  }