import Condition from "./condition";

export default class ConditionList {
    constructor (conditions, parent) {
        this.conditions = (conditions || []).map(c => new Condition(c, this))
        this.getParent = () => parent
    }

    get empty () {
        return this.conditions.length === 0
    }

    export () {
        return this.conditions.map(c => c.export())
    }

    addNewCondition (index, expression = '') {
        let newCondition = new Condition({ expression: expression }, this)
        this.conditions.splice(index + 1, 0, newCondition)
        return newCondition
    }

    onUpdated () {
        if (this.getParent()) this.getParent().onUpdated()
    }

    getConditionIndex (id) {
        return this.conditions.map(c => c.id).indexOf(id)
    }

    remove (condition) {
        let indexToRemove = this.getConditionIndex(condition.id)
        this.conditions.splice(indexToRemove, 1)
        this.onUpdated()
    }

    static replaceVariableInConditions(conditions, query, replacement, pattern){
        conditions.forEach(condition => {
            if (condition.expression && condition.expression.match(pattern)){
                let queryReplacement = []
                condition.expression.match(pattern).forEach((q, index) => {
                    queryReplacement.push(q.replace(query, replacement))
                    condition.expression = condition.expression.replace(q, queryReplacement[index])
                })    
            }
            if(condition.subconditions && condition.subconditions.conditions.length > 0){
                ConditionList.replaceVariableInConditions(condition.subconditions.conditions, query, replacement, pattern)
            }
        });

    }
}