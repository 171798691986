import uniqueId from 'lodash/uniqueId'
import SentenceList from './sentenceList'
import VariantList from './variantList';
import ConditionList from './conditionList';
import { useEditorStore } from '@/stores/editor'
import { set } from 'lodash';
// import Store from '@/store/store.js'
export default class Sentence {
    constructor (sentence, parent) {
        this.conditionList = new ConditionList(sentence.conditions, this)
        this.conditionOperator = sentence.conditionOperator || 'And'
        this.sentenceList = new SentenceList(sentence.sentences, this)
        this.variantList = new VariantList(sentence.texts, this)
        this.number = sentence.number || 0
        this.priority = sentence.priority || 0
        this.name = sentence.name || ''
        this.id = uniqueId()
        this.getParent = () => parent
        this.path = null // path will be set by the parent
        this.sentenceType = sentence.sentenceType
        this.tableColumnCount = sentence.tableColumnCount || null
    }

    get empty () {
        return this.sentenceList.empty && this.variantList.empty
    }

    // return the minimum sentence for JSON purposes
    export () {
        let exportable = { number: this.number, priority: this.priority, conditionOperator: this.conditionOperator, sentenceType: this.sentenceType }
        if (!this.conditionList.empty) exportable.conditions = this.conditionList.export()
        if (!this.variantList.empty) exportable.texts = this.variantList.export()
        if (!this.sentenceList.empty) exportable.sentences = this.sentenceList.export()
        if (this.name) exportable.name = this.name
        if (this.tableColumnCount) exportable.tableColumnCount = this.tableColumnCount
        return exportable
    }

    addVariant (text) {
        return this.variantList.add(text)    
    }

    setPath (path) {
        this.path = path
        if (!this.variantList.empty) this.variantList.setPaths(this.path)
        if (!this.sentenceList.empty) this.sentenceList.setPaths(this.path)
    }

    setPathsLazy (path, setNext = false) {
        this.path = path
        if(!setNext) return;
        if (!this.variantList.empty) this.variantList.setPathsLazy(this.path)
        if (!this.sentenceList.empty) this.sentenceList.setPathsLazy(this.path)
    }

    clone () {
        return new Sentence(this.export())
    }

    clean () {
        let changes = false
        let scenarioGroups = Object.values(this.sentenceList.scenarioGroups)
        if (scenarioGroups.length == 1 && scenarioGroups[0].length == 1) {
            // there is only one scenariogroup and it only has one sentence in it
            let sentence = scenarioGroups[0][0]
            if (sentence.conditionList.empty && sentence.sentenceList.empty) {
                // and there are no conditions and no sentences -> let's move the variants up to this level
                this.variantList = sentence.variantList
                this.sentenceList.clear()
                changes = true
            }
        }
        return changes
    }

    onUpdated () {
        // this.clean()
        if (this.getParent()) this.getParent().onUpdated()
    }
    
    addSentences(sentences){
        this.sentenceList.sentences = sentences
        // this.sentenceList = new SentenceList(sentences, this)
        this.onUpdated()
    }

    rename(name){
        console.log("New name: ", name)
        this.name = name
        this.onUpdated()
    }

    convertVariantToSentence (i, isGlobal) {
        let variants = this.variantList.variants.map(v => v.text)
        let sentences;
        if ( isGlobal ) {
            sentences = [{ texts: variants.slice(0, i), number: 0, priority: 0, sentences: [], conditions: [], wasVariant: true, sentenceType: "Sentence" }]
        } else {
            sentences = [{ texts: variants.slice(i, i + 1), number: 0, priority: 0, sentences: [], conditions: [], wasVariant: true, sentenceType: "Sentence" }]
            if (i > 0) sentences.unshift({ texts: variants.slice(0, i), number: 0, priority: 0, sentences: [], conditions: [], sentenceType: "Sentence" })
            if (i < this.variantList.variants.length - 1) sentences.push({ texts: variants.slice(i + 1, variants.length), number: 0, priority: 0, sentences: [], conditions: [], sentenceType: "Sentence" })
        }
        this.sentenceList = new SentenceList(sentences, this)
        this.variantList.clear()
        this.setPath(this.path)
        // this.getParent().setPath(this.getParent().path)
        return this.sentenceList.sentences[sentences.map(s => s.wasVariant).indexOf(true)]
    
    }

    findVariantMatchesByQuery (query, caseSensitive) {
        return this.variantList.variants.filter(v => {
            return caseSensitive ? v.text.indexOf(query) > -1 : v.text.toLowerCase().indexOf(query) > -1
        })
    }

    findVariantMatchesByRegex (pattern) {
        return this.variantList.variants.filter(v => {
            return v.text.match(pattern)
        })
    }

    replaceVariantMatchesByQuery(query, replacement){
        this.variantList.variants.forEach(v => {
         v.text = v.text.replaceAll(query, replacement)
        })
    }

    replaceConditions(query, replacement, pattern){
        ConditionList.replaceVariableInConditions(this.conditionList.conditions, query, replacement, pattern)
    }

    replaceVariantMatchesByRegexQuery(query, replacement, pattern){
        this.variantList.variants.forEach(v => {
         let queryReplacement = []
         if(pattern && pattern.test(v.text)){
            v.text.match(pattern).forEach((q, index) => {
                queryReplacement.push(q.replace(query, replacement))
                v.text = v.text.replace(q, queryReplacement[index])
            })
         }   
        })
    }

    transformGlobalSynToSyn(synonym, pattern){
        const Store = useEditorStore()
        let formats = []
        this.variantList.variants.forEach(v => {
             if(pattern && pattern.test(v.text)){
                v.text.match(pattern).forEach(q => {
                    if(q.includes("|")){ formats.push(q.split("|")[1].slice(0, -1)) }
                })
             }   
        })
        if(formats.length > 0){
            Store.getSynonymFormatExamples({synonym: synonym, formats: formats, all: true}).then(res => {
                this.variantList.variants.forEach(v => {
                    if(v.text.match(pattern)){
                        v.text.match(pattern).forEach(q => {
                            if(q.includes("|")){
                                const formattedSynResults = res.filter(syn => syn.format == q.split("|")[1].slice(0, -1))
                                v.text = v.text.replace(q, `:syn(${formattedSynResults.map(s => s.result).join("|")})`)
                            } else{
                                v.text = v.text.replace(q, `:syn(${synonym.synonyms.join("|")})`)
                            }
                        })
                    }
                })
            })
        } else {
            this.variantList.variants.forEach(v => {
                v.text = v.text.replaceAll(pattern, `:syn(${synonym.synonyms.join("|")})`)
            })
        }
    }

    static findMatchesRecursivelyByRegex(sentence, query, matches) {
        
        Array.prototype.push.apply(matches, sentence.findVariantMatchesByRegex(query))
        
        sentence.sentenceList.sentences.forEach(s => Sentence.findMatchesRecursivelyByRegex(s, query, matches))
    }

    static findMatchesRecursively(sentence, query, matches, caseSensitive) {
        
        Array.prototype.push.apply(matches, sentence.findVariantMatchesByQuery(query, caseSensitive))
        
        sentence.sentenceList.sentences.forEach(s => Sentence.findMatchesRecursively(s, query, matches, caseSensitive))
    }
    
    
    static replaceByRegex(sentence, query, matches, replacement, pattern) {
        
        Array.prototype.push.apply(matches, sentence.replaceVariantMatchesByRegexQuery(query, replacement, pattern))
        
        sentence.sentenceList.sentences.forEach(s => Sentence.replaceByRegex(s, query, matches, replacement, pattern))
    }
    
    static transformSynonyms(sentence, synonym, pattern, matches) {
        
        Array.prototype.push.apply(matches, sentence.transformGlobalSynToSyn(synonym, pattern))
        
        sentence.sentenceList.sentences.forEach(s => Sentence.transformSynonyms(s,  synonym, pattern, matches))
    }

    static findSentenceListIdRecursively(sentence, id) {
        if(sentence.sentenceList.id != id){
            let match;
            sentence.sentenceList.sentences.forEach(s => {
                let sentenceList = Sentence.findSentenceListIdRecursively(s, id)
                if(sentenceList && sentenceList.id == id){match = sentenceList}
            })
            return match
        } else return sentence.sentenceList
    }

    static replaceVariablesInConditions(sentence, query, replacement, pattern) {
        
        sentence.replaceConditions(query, replacement, pattern)
        
        sentence.sentenceList.sentences.forEach(s => Sentence.replaceVariablesInConditions(s,  query, replacement, pattern))
    }
}