export default {
  emailRegex:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  urlRegex: /^(?:(ftp|http|https)?:\/\/)?(?:[\w-]+\.)+([a-z]|[A-Z]|[0-9]){2,6}$/gi,

  toCamelCase(string) {
    return string.charAt(0).toLowerCase() + string.substring(1);
  },

  copyToClipboard(string) {
    navigator.clipboard.writeText(string);
  },

  stringifyObjectValues(obj) {
    let stringyfiedValues = {};
    for (const key in obj) {
      if (obj[key] || obj[key] == false) stringyfiedValues[key] = obj[key].toString();
    }
    return stringyfiedValues;
  },

  removeObjectKey(obj, key) {
    if (!obj) return {};
    if (obj && obj[key]) {
      const { [key]: unused, ...rest } = obj;
      return rest;
    } else return obj;
  },

  getBaseUrl() {
    return process.env.NODE_ENV != 'development'
      ? window.location.origin.includes('staging')
        ? 'https://staging.app.icrobotics.com'
        : 'https://app.icrobotics.com'
      : window.location.origin;
  },

  resizeTextArea(area) {
    area.style.overflow = 'hidden';
    area.style.height = 0;
    area.style.height = area.scrollHeight + 'px';
  },

  getDeviceType() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
    ) {
      return 'mobile';
    }
    return this.detectTabletOrDesktop();
  },

  detectTabletOrDesktop() {
    return /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1
      ? 'tablet'
      : 'desktop';
  },

  getApiUrl() {
    const baseUrl = document.location.hostname;
    const staging = ['staging', '192.168', '127.0.0.1', 'localhost', 'gray-cliff-0cd37bd03'];
    // return "https://localhost:5001/";
    // return 'https://staging.api.icrobotics.com/';

    if (staging.some((el) => baseUrl.includes(el))) {
      return 'https://staging.api.icrobotics.com/';
    } else if (baseUrl.includes('dev.')) {
      return 'http://dev.api.icrobotics.com:63342/';
    } else if (baseUrl.includes('icrapp-dev-env') || baseUrl.includes('dev')) {
      return `https://icrapi-dev-env-${baseUrl.charAt(baseUrl.search(/[0-9]/))}.azurewebsites.net/`;
    } else {
      return 'https://api.icrobotics.com/';
    }
  },

  setAttributes(element, attributes) {
    for (let key in attributes) {
      element.setAttribute(key, attributes[key]);
    }
  },

  formatDate(dateString) {
    if (dateString == '0001-01-01T00:00:00') return 'Never';
    const date = new Date(dateString);
    let options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    return new Intl.DateTimeFormat('default', options).format(date);
  },

  formatDateSeconds(dateString) {
    const date = new Date(dateString);
    return date.getTime();
  },

  getElementExceedingSides(element) {
    const elementRect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    
    const exceeds = {};
    // Check if any of the element's edges exceed the window boundaries
    if (elementRect.top < 0) {
      exceeds.top = true;
    }
    if (elementRect.bottom > windowHeight) {
      exceeds.bottom = true;
    }
    if (elementRect.left < 0) {
      exceeds.left = true;
    }
    if (elementRect.right > windowWidth) {
      exceeds.right = true;
    }
    return exceeds;
  },
};
