export default {
  gsynPattern: /:gsyn\((?:[^)(]+)\)/g,
  synPattern: /:syn\((?:[^)(]+)\)/g,

  removeFormatting(synonymName) {
    return synonymName && synonymName.indexOf('|') > -1
      ? synonymName.substring(synonymName.indexOf('(') + 1, synonymName.indexOf('|'))
      : synonymName.substring(synonymName.indexOf('(') + 1, synonymName.indexOf(')'));
  },
  getFormatting(name) {
    let position = name.indexOf('|');
    return position > -1 ? name.substr(position + 1, name.length - position - 2) : '';
  },

  getList(synonym) {
    return synonym.substring(synonym.indexOf('(') + 1, synonym.lastIndexOf(')'));
  },

  getFirstInstance(synonym) {
    return synonym.substring(synonym.indexOf('(') + 1, synonym.indexOf('|'));
  },

  countList(synonym){
    return this.getList(synonym).split('|').length;
  },
  
  getGsynName(gsyn) {
    const withoutFormats = this.removeFormatting(gsyn);
    return withoutFormats.substring(withoutFormats.indexOf("(") + 1, withoutFormats.lastIndexOf(")"));
  },

  removeGsynFormatting(gsyn) {
    return gsyn && gsyn.indexOf("|") > -1 ? gsyn.substring(0, gsyn.indexOf("|")) + ")" : gsyn;
  },

  globalSynonymsInText(text) {
    return text.match(this.gsynPattern) || [];
  },
  synonymsInText(text) {
    return text.match(this.synPattern) || [];
  },

};
