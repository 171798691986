export default {
	pattern: /{.*?\}/g,

	isVariable(text){
		return text.match(/{.*?\}/g)
	},

	removeFormatting(variableName) {
		return variableName && variableName.indexOf(':') > -1
				? variableName.substring(0, variableName.indexOf(':')) + '}'
				: variableName
	},
	getFormatting(name) {
		let position = name.indexOf(':')
		return position > -1
				? name.substr(position + 1, name.length - position - 2)
				: ''
	},
	insertFormatting(name, formatting) {
		if (name) {
			return name.replace('}', ':' + formatting + '}')
		} else {
			return name
		}
	},
	getByName(name, variables) {
		let withoutFormatting = this.removeFormatting(name)
		return variables[withoutFormatting] || null
	},
	getPrettyName(name, variables, includeFormatting) {
		let variable = this.getByName(name, variables)
		if (variable) {
			if(variable.name.includes("_") && !variable.displayName) { return {output:  variable.name.substring(variable.name.lastIndexOf('_') + 1, variable.name.length-1) , notFound: false}} // Test for now to make extra variables pretty in the editor.
			else{
				let formatting = this.getFormatting(name)
				return {output: this.extractPrettyName(variable) + (includeFormatting && formatting ? ':' + formatting : ''), notFound: false }
			}
		}
		else return {output: this.extractPrettyNameVariableOnly({name}), notFound: true}
	},
	extractPrettyName(variable) {
		return variable.displayName || variable.name.replace(/[{}]/g, '')
	},
	extractPrettyNameVariableOnly(variable) {
		return this.extractPrettyName(variable).split('_').pop()
	},
	removeBrackets (text) {
		return text ? text.replace(/[{}]/g, '') : ''
	},
	findInText(text) {
		return text.match(this.pattern)
	},
	findVariablesInText(text, variables) {
		return (this.findInText(text) || []).map(v => this.getByName(v, variables)).filter(v => !!v)
	},
	getDependentVariables (expression, variables) {
		let getRecursively = (expression, aggregated) => {
			let varsInText = (this.findInText(expression) || []).map(v => this.getByName(v, variables)).filter(v => !!v)
			varsInText.forEach(current => {
				// if (!current.custom) aggregated[current.name] = current
				aggregated[current.name] = current
				if (current.custom && current.referencedVariables) {
					current.referencedVariables.forEach(v => {		
						let varsInVariableValue = getRecursively((`{${v}}` || '').toString(), aggregated)
						Object.values(varsInVariableValue).forEach(v => aggregated[v.name] = v)
					})
				}
			})
			return aggregated
		}
		return Object.values(getRecursively(expression, {}))
	},

	countVariablesInTemplate (template) {
		let findVariablesInText = (text) => {
			const matchedText = text.match(/{.*?\}/g)
			if (matchedText) {
				return matchedText.filter(v => v !== '')
			} else return []
		}

		let getVariablesRecursively = sentence => {
			
			let conditionVariables = (sentence.conditionList.conditions || []).reduce((all, condition) => all.concat(findVariablesInText(condition.expression || condition.subconditions.conditions.map(sc => sc.expression).join())), [])
			
			
			if (!sentence.sentenceList.empty) {
				return sentence.sentenceList.sentences.reduce((all, sentence) => all.concat(getVariablesRecursively(sentence)), conditionVariables)
			} else {
				return (sentence.variantList.variants).reduce((all, variantText) => {					
					return all.concat(findVariablesInText(variantText.text))
				}, conditionVariables)
			}
		}
	
	

		let allVars = {}
		let allSentences = !template ? [] : template.sections.reduce((all, current) => all.concat(current.sentences.sentences), [])
		allSentences.forEach(sentence => {
			getVariablesRecursively(sentence).map(v => this.removeFormatting(v)).forEach(v => {
				allVars[v] = (allVars[v] || 0) + 1
			})
		})

		// console.log(allVars)
		return allVars
	},
	groupVariables (variables) {
		let groups = { name: '', groups: [], variables: [], root: true }
		Object.values(variables).forEach(v => {
        let splits = v.name.replace('{', '').replace('}', '').split('_')
        let currentGroup = groups
        splits.forEach((split, i) => {
          if (i == splits.length - 1) {
            currentGroup.variables.push(v)
          } else {
            currentGroup = currentGroup.groups.find(g => g.name === split) || currentGroup.groups[currentGroup.groups.push({ name: split, groups: [], variables: [] }) - 1]
          }
        })
      })
      return groups
	}
}
