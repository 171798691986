<template>
  <div class="fixed top-4 right-8 flex flex-col">
    <TransitionGroup tag="ul" name="fade">
      <li v-for="(message, index) in messages" :key="message.id">
        <div class="p-3 mb-2 bg-charcoal rounded-md flex items-center justify-between gap-2 w-[350px] shadow-2xl">
          <div class="flex items-center gap-2">
            <!-- Icons -->
            <div v-if="message.icon">
              <span class="text-2xl flex items-center justify-center" :class="message.iconStyling">
                <i class="fa-light" :class="message.iconClass"></i>
              </span>
            </div>
            <span class="font-normal text-xs mr-6" :class="message.iconStyling"> {{ message.message }}</span>
          </div>
          <!-- Actions -->
          <div class="flex gap-4 items-center">
            <button v-if="message.action" class=" text-orangeWeb text-xs whitespace-nowrap" @click="message.action">
              {{ message.actionText }}
            </button>
            <button
              v-if="message.closeBtn"
              class="text-white flex items-center justify-center"
              @click="hideMessage(index)"
            >
              <i class="fa-light fa-times"></i>
            </button>
          </div>
        </div>
      </li>
    </TransitionGroup>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import Snackbar from '@/assets/js/snackbar.js';

const messages = ref([]);

const newMessage = (msg) => {
  const instance = new Snackbar(msg);
  messages.value.push(instance.element);
  setTimeout(() => {
    const messageIndex = messages.value.findIndex((message) => message.id === instance.element.id);
    messages.value.splice(messageIndex, 1);
  }, instance.element.timeVisible);
};

const hideMessage = (index) => {
  messages.value.splice(index, 1);
};

defineExpose({
  newMessage,
});
</script>
<style>
/* .fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translate(200px, 0);
}

.fade-leave-active {
  position: absolute;
} */
</style>
